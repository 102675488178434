import './video-gallery.scss';

import { Slider } from '../slider/slider';

import { YoutubePlayer } from '../../utilities/js/youtube-player';

class VideoGallery {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-video-gallery',
            slidesPerView: 1,
            slidesPerGroup: 1
        };

        this.settings = Object.assign({}, defaults, options);
        this.$videoGallery = element;
        this.$videos = this.$videoGallery.querySelectorAll('[data-videoplayer]');
        this.videos = [];
        this.slider = '';
    }

    initialize () {
        this.initSlider(this.$videoGallery);

        this.$videos.forEach((video, index) => {
            this.initYoutubePlayer(video, index);
        });
    }

    initSlider (slider) {
        this.slider = new Slider(slider, {
            initAttr: this.settings.initAttr,
            breakpoints: null,
            calculateHeight: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            speed: 750,
            onSlide: () => {
                this.videos.forEach(video => {
                    if (video.playing) {
                        video.player.pauseVideo();
                    }
                });
            }
        });
    }

    initYoutubePlayer ($video, index) {
        if ($video !== null) {
            if (window.youtubeReady) {
                this.videos[index] = new YoutubePlayer($video, {
                    eventScroller: window.eventScroller,
                    onEnd: () => {
                        if (this.videos[index].player.video.isMuted() === false || this.videos[index].mute === 0) {
                            this.videos[index].destroy();
                        }
                    }
                });
            }
        }
    }
}

export { VideoGallery };

window.addEventListener('youtubeApiLoaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$videoGallery = $context.querySelectorAll('[data-video-gallery="root"]');
        for (let i = 0; i < $$videoGallery.length; i++) {
            const $videoGallery = new VideoGallery($$videoGallery[i]);
            $videoGallery.initialize();
        }
    }
});
