class YoutubePlayer {
    constructor (element, options) {
        const settings = {
            autoplay: false,
            eventScroller: null,
            initAttr: 'data-videoplayer',
            loop: false,
            offset: 0,
            playerSettings: {
                showinfo: 0,
                rel: 0,
                color: 'white',
                height: 720,
                width: 1280
            },
            onCall: null,
            onStart: null,
            onResume: null,
            onEnd: null,
            onPause: null
        };

        this.settings = Object.assign({}, settings, options);
        this.$youtubePlayer = element;
        this.youtubeId = this.$youtubePlayer.getAttribute(this.settings.initAttr);
        this.playerId = 'yt_' + Math.random().toString(16).slice(-4) + '_' + this.youtubeId; // generate unique module id
        this.playing = false;

        this.touchDevice = window.matchMedia('(pointer:coarse)').matches;

        this.autoplay = this.settings.autoplay;
        if (this.$youtubePlayer.getAttribute('autoplay') !== null) {
            this.autoplay = true;
        }

        this.mute = 0;
        if (this.autoplay === true) {
            this.mute = 1;
        }

        this.loop = this.settings.loop;
        if (this.$youtubePlayer.getAttribute('loop') !== null) {
            this.loop = true;
        }

        if (this.settings.eventScroller !== null) {
            this.eventScroller = this.settings.eventScroller;
        }

        if (this.youtubeId === '') {
            console.info('no youtube id specified');
            return;
        }

        if (typeof window.youtubePlayer === 'undefined') {
            window.youtubePlayer = [];
        }

        this.initialize();
    }

    _buildPlayer () {
        let firstStart = true;

        // remove old player from element
        const $oldPlayer = document.getElementById(this.playerId);
        if ($oldPlayer) {
            $oldPlayer.parentNode.removeChild($oldPlayer);
        }

        // create new container for yt api
        const $player = document.createElement('div');
        $player.setAttribute('id', this.playerId);
        $player.classList.add('player');
        this.$youtubePlayer.append($player);

        this.player = new window.YT.Player(this.playerId, {
            height: this.settings.playerSettings.height,
            width: this.settings.playerSettings.width,
            videoId: this.youtubeId,
            host: 'https://www.youtube-nocookie.com',
            playerVars: {
                showinfo: this.settings.playerSettings.showinfo,
                rel: this.settings.playerSettings.rel,
                color: this.settings.playerSettings.color,
                fs: 1,
                playsinline: 0
            },
            events: {
                onReady: () => {
                    if (this.mute === 1) {
                        this.player.mute();
                        this.$youtubePlayer.classList.add('is--muted');
                    }

                    window.youtubePlayer[this.playerId] = this;

                    this.player.playVideo();
                },
                onStateChange: (event) => {
                    if (event.data === 1) { // start video
                        if (this.playing === false) {
                            this.$youtubePlayer.classList.add('is--playing');

                            this._pauseRunningPlayer(this);

                            this.playing = true;
                        }

                        if (firstStart === true) {
                            firstStart = false;

                            if (typeof this.settings.onStart === 'function') {
                                this.settings.onStart(this.youtubeId);
                            }
                        } else {
                            if (typeof this.settings.onResume === 'function') {
                                this.settings.onResume(this.youtubeId);
                            }
                        }
                    } else if (event.data === 2) { // pause video
                        this.playing = false;
                        this.$youtubePlayer.classList.remove('is--playing');

                        if (typeof this.settings.onPause === 'function') {
                            const progressPercentage = parseInt(100 / this.player.getDuration() * this.player.getCurrentTime());
                            this.settings.onPause(this.youtubeId, progressPercentage);
                        }
                    } else if (event.data === 0) { // video ended
                        if (this.autoplay === true && this.mute === 1) {
                            this.player.playVideo();
                        } else {
                            this.destroy();
                        }
                    }
                    window.youtubePlayer[this.playerId] = this;
                }
            }
        });
    }

    _pauseRunningPlayer (current) {
        const runningPlayers = window.youtubePlayer;

        for (const key in runningPlayers) {
            const runningPlayer = runningPlayers[key];
            if (runningPlayer !== current) {
                if (runningPlayer.mute === 0 && runningPlayer.player !== null) {
                    runningPlayer.player.pauseVideo();
                }
            }
        }
    }

    initialize () {
        if (!this.touchDevice && this.autoplay === true) {
            this._buildPlayer();
        } else {
            this.mute = 0;
        }

        this.$youtubePlayer.classList.add('is--available');
        this.$youtubePlayer.addEventListener('click', (e) => {
            e.preventDefault();
            if (typeof this.settings.onCall === 'function') {
                this.settings.onCall();
            }
            console.log(this.player, typeof this.player);
            if (typeof this.player !== 'undefined' && this.player !== null) {
                if (this.playing === true) {
                    this.player.pauseVideo();

                    if (this.mute === 1) {
                        this.$youtubePlayer.classList.remove('is--muted');

                        this.mute = 0;
                        this.player.unMute();
                        this.player.seekTo(0);
                        this.player.playVideo();
                    }
                } else {
                    this.player.playVideo();
                }
            } else {
                this._buildPlayer();
            }
        });
    }

    destroy () {
        this.playing = false;
        this.$youtubePlayer.classList.remove('is--available');
        this.$youtubePlayer.classList.remove('is--playing');
        this.$youtubePlayer.classList.remove('is--muted');
        this.player = null;

        window.youtubePlayer[this.playerId] = null;

        const $player = document.getElementById(this.playerId);
        if (typeof $player !== 'undefined') {
            $player.parentNode.removeChild($player);
        }
    }
}

export { YoutubePlayer };
